:global(.leaflet-tooltip) {
  border: 0;
  padding: 0;
}

:global(.leaflet-tooltip-top) {
  margin-top: -12px;
}

:global(.leaflet-tooltip-top:before) {
  border-top-color: rgba(var(--clrPrimary));
}

.container {
  padding: 12px 24px;
  background-color: rgba(var(--clrPrimary));
}

.container.warning {
  background-color: rgba(var(--clrWarning));
}

.header {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 6px;
}
