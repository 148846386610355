.container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.backButton {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 1024;
  box-shadow: 0 1px 5px rgba(var(--clrBlack), var(--alpha));
}

.backButton:hover {
  background-color: rgba(var(--clrNtrlDark));
  opacity: 1;
}

.backButton:hover > i {
  fill: rgba(var(--clrWhite));
}

.header {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1024;
  display: flex;
  gap: 18px;
  align-items: flex-start;
}

.actions {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
}

.trackinfo {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 12px 0;
}

.leyend {
  display: flex;
  gap: 12px;
}

.leyend span {
  display: flex;
  align-items: center;
  gap: 6px;
}

.leyend span:before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: solid 2px rgba(var(--clrNtrlDark));
  box-sizing: border-box;
}

.leyend span.stop:before {
  border-color: #3388ff;
  background-color: rgba(51, 136, 255, 0.4);
}

.leyend span.start:before {
  border-color: green;
  background-color: rgba(0, 128, 0, 0.4);
}

.leyend span.end:before {
  border-color: red;
  background-color: rgba(255, 0, 0, 0.4);
}

.container .vehicleFile {
  left: calc(24px + 20%);
}
