.container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.content {
  flex: 1;
  overflow: auto;
}

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
}

.filtersWrapper {
  display: flex;
  align-items: center;
  flex: 1;
}

.table {
  min-width: 600px;
}

.tableRow > td {
  padding: 6px 12px;
}

.tableRow > td:first-of-type {
  max-width: 30px;
}

.tableRow:hover {
  background-color: rgba(var(--clrNtrlLight));
}

.tableRow + .tableRow {
  border-top: solid 1px rgba(var(--clrNtrlLight));
}

.checkbox {
  justify-content: center;
}

.pointerNone {
  pointer-events: none;
  cursor: pointer;
}

.headCell {
  text-align: left;
  min-width: 120px;
  cursor: pointer;
  user-select: none;
}

tr .headCell:is(:nth-child(2), :nth-child(1)) {
  min-width: unset;
}

tr .headCell:nth-child(1) {
  width: 60px;
  box-sizing: border-box;
}

.headCell > p {
  display: inline-block;
  margin-right: 6px;
}

.actions {
  display: flex;
  align-items: center;
}

.postionCell {
  display: flex;
  align-items: flex-start;
  gap: 3px;
}

.warningIcon {
  flex-shrink: 0;
}
