.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.loaderWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(var(--clrNtrlLight));
}

.contentAnimatableWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(var(--clrNtrlLight));
}

:global(.fade-enter) {
  opacity: 0;
}

:global(.fade-enter-active).contentAnimatableWrapper {
  opacity: 1;
}

:global(.fade-exit).contentAnimatableWrapper {
  opacity: 1;
}

:global(.fade-exit-active).contentAnimatableWrapper {
  opacity: 0;
}

:global(.fade-enter-active).contentAnimatableWrapper,
:global(.fade-exit-active).contentAnimatableWrapper {
  transition: opacity 0.3s;
}
