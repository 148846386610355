.button {
  position: relative;
  padding: 0 24px;
  height: 36px;
  border-radius: 6px;
  background-color: transparent;
  border: solid 1px transparent;
  font-size: 16px;
  color: rgba(var(--clrWhite));
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  /* fix a strange behavior with transparency hover that increase the grid height */
  transform: translateZ(0);
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0;
  min-width: 120px;
}

.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 16px;
}

/* Variant */
.black.inverted {
  border-color: rgba(var(--clrBlack));
}

.black.inverted:active,
.black.inverted:hover {
  background-color: rgba(var(--clrBlack));
  color: rgba(var(--clrWhite));
}

.black {
  background-color: rgba(var(--clrBlack));
}

.white.inverted {
  border-color: rgba(var(--clrWhite));
}

.white {
  background-color: rgba(var(--clrWhite));
}

.max.inverted {
  border-color: rgb(var(--clrNtrlMax));
}

.max.inverted:active,
.max.inverted:hover {
  background-color: rgba(var(--clrNtrlMax));
  color: rgba(var(--clrWhite));
}

.max {
  background-color: rgb(var(--clrNtrlMax));
}

.dark.inverted {
  border-color: rgb(var(--clrNtrlDark));
}

.dark.inverted:active,
.dark.inverted:hover {
  background-color: rgba(var(--clrNtrlDark));
  color: rgba(var(--clrWhite));
}

.dark {
  background-color: rgb(var(--clrNtrlDark));
}

.light.inverted {
  border-color: rgb(var(--clrNtrlLight));
}

.light.inverted:active,
.light.inverted:hover {
  background-color: rgba(var(--clrNtrlLight));
  color: rgba(var(--clrWhite));
}

.light {
  background-color: rgb(var(--clrNtrlLight));
}

.min.inverted {
  border-color: rgb(var(--clrNtrlMin));
}

.min.inverted:active,
.min.inverted:hover {
  background-color: rgba(var(--clrNtrlMin));
  color: rgba(var(--clrWhite));
}

.min {
  background-color: rgb(var(--clrNtrlMin));
}

.primary.inverted {
  border-color: rgba(var(--clrPrimary));
}

.primary.inverted:active,
.primary.inverted:hover {
  background-color: rgba(var(--clrPrimary));
  color: rgba(var(--clrWhite));
}

.primary {
  background-color: rgba(var(--clrPrimary));
}

.primaryLight.inverted {
  border-color: rgba(var(--clrPrimaryLt));
}

.primaryLight.inverted:active,
.primaryLight.inverted:hover {
  background-color: rgba(var(--clrPrimaryLt));
  color: rgba(var(--clrWhite));
}

.primaryLight {
  background-color: rgba(var(--clrPrimaryLt));
}

.secondary.inverted {
  border-color: rgba(var(--clrSecondary));
}

.secondary.inverted:active,
.secondary.inverted:hover {
  background-color: rgba(var(--clrSecondary));
  color: rgba(var(--clrWhite));
}

.secondary {
  background-color: rgba(var(--clrSecondary));
}

.secondaryLight.inverted {
  border-color: rgba(var(--clrSecondaryLt));
}

.secondaryLight.inverted:active,
.secondaryLight.inverted:hover {
  background-color: rgba(var(--clrSecondaryLt));
  color: rgba(var(--clrWhite));
}

.secondaryLight {
  background-color: rgba(var(--clrSecondaryLt));
}

.info.inverted {
  border-color: rgba(var(--clrInfo));
}

.info.inverted:active,
.info.inverted:hover {
  background-color: rgba(var(--clrInfo));
  color: rgba(var(--clrWhite));
}

.info {
  background-color: rgba(var(--clrInfo));
}

.positive.inverted {
  border-color: rgba(var(--clrPositive));
}

.positive.inverted:active,
.positive.inverted:hover {
  background-color: rgba(var(--clrPositive));
  color: rgba(var(--clrWhite));
}

.positive {
  background-color: rgba(var(--clrPositive));
}

.negative.inverted {
  border-color: rgba(var(--clrNegative));
}

.negative.inverted:active,
.negative.inverted:hover {
  background-color: rgba(var(--clrNegative));
  color: rgba(var(--clrWhite));
}

.negative {
  background-color: rgba(var(--clrNegative));
}

.warning.inverted {
  border-color: rgba(var(--clrWarning));
}

.warning.inverted:active,
.warning.inverted:hover {
  background-color: rgba(var(--clrWarning));
  color: rgba(var(--clrWhite));
}

.warning {
  background-color: rgba(var(--clrWarning));
}

.panda.inverted {
  border-color: rgba(var(--clrPanda));
}

.panda.inverted:active,
.panda.inverted:hover {
  background-color: rgba(var(--clrPanda));
  color: rgba(var(--clrWhite));
}

.panda {
  background-color: rgba(var(--clrPanda));
}

.pandaDark.inverted {
  border-color: rgba(var(--clrPandaDark));
}

.pandaDark.inverted:active,
.pandaDark.inverted:hover {
  background-color: rgba(var(--clrPandaDark));
  color: rgba(var(--clrWhite));
}

.pandaDark {
  background-color: rgba(var(--clrPandaDark));
}

.flamingo.inverted {
  border-color: rgba(var(--clrFlamingo));
}

.flamingo.inverted:active,
.flamingo.inverted:hover {
  background-color: rgba(var(--clrFlamingo));
  color: rgba(var(--clrWhite));
}

.flamingo {
  background-color: rgba(var(--clrFlamingo));
}

.cocodrile.inverted {
  border-color: rgba(var(--clrCocodrile));
}

.cocodrile.inverted:active,
.cocodrile.inverted:hover {
  background-color: rgba(var(--clrCocodrile));
  color: rgba(var(--clrWhite));
}

.cocodrile {
  background-color: rgba(var(--clrCocodrile));
}

.pig.inverted {
  border-color: rgba(var(--clrPig));
}

.pig.inverted:active,
.pig.inverted:hover {
  background-color: rgba(var(--clrPig));
  color: rgba(var(--clrWhite));
}

.pig {
  background-color: rgba(var(--clrPig));
}

.koala.inverted {
  border-color: rgba(var(--clrKoala));
}

.koala.inverted:active,
.koala.inverted:hover {
  background-color: rgba(var(--clrKoala));
  color: rgba(var(--clrWhite));
}

.koala {
  background-color: rgba(var(--clrKoala));
}

.parrot.inverted {
  border-color: rgba(var(--clrParrot));
}

.parrot.inverted:active,
.parrot.inverted:hover {
  background-color: rgba(var(--clrParrot));
  color: rgba(var(--clrWhite));
}

.parrot {
  background-color: rgba(var(--clrParrot));
}

.parrotLight.inverted {
  border-color: rgba(var(--clrParrotLight));
}

.parrotLight.inverted:active,
.parrotLight.inverted:hover {
  background-color: rgba(var(--clrParrotLight));
  color: rgba(var(--clrWhite));
}

.parrotLight {
  background-color: rgba(var(--clrParrotLight));
}

.octupus.inverted {
  border-color: rgba(var(--clrOctopus));
}

.octupus.inverted:active,
.octupus.inverted:hover {
  background-color: rgba(var(--clrOctopus));
  color: rgba(var(--clrWhite));
}

.octupus {
  background-color: rgba(var(--clrOctopus));
}

/* should be placed at the end to override de
background color of positive, negative... types */
.inverted {
  background: transparent;
  color: rgba(var(--clrNtrlMax));
}

.button:not(.inverted):active,
.button:not(.inverted):hover {
  opacity: 0.6;
}

.button[disabled],
.button[disabled].inverted {
  opacity: var(--alpha);
  cursor: default;
}

.button[disabled]:active,
.button[disabled]:hover {
  opacity: var(--alpha);
}
