.container {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
}

.backButton {
  width: 24px;
  margin-right: 6px;
}

.container form {
  max-width: 80%;
}

.submitButton {
  flex: 0;
  margin-top: 24px;
}
