.container {
  position: relative;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  height: 20px;
  width: 20px;
  background-color: rgba(var(--clrWhite));
  border: solid 1px rgba(var(--clrNtrlMax), var(--alpha));
  border-radius: 3px;
  position: relative;
}

.checkmark::after {
  content: '';
  margin: 2px;
  position: absolute;
  border-radius: 3px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(var(--clrPrimary));
  transform: scale(0);
  transition: transform 0.3s ease;
}

.label {
  margin-left: 12px;
}

input:checked ~ .checkmark::after {
  transform: scale(1);
}

.icon {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
