:global(.datepicker.disabled) {
  opacity: var(--alpha);
  pointer-events: none;
}

:global(.datepicker
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)) {
  background-color: rgba(var(--clrNtrlLight)) !important;
  color: rgba(var(--clrNtrlDark));
}

:global(.datepicker .DayPicker-Day) {
  border-radius: 0 !important;
}

:global(.datepicker
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--start) {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  background-color: rgba(var(--clrPrimary));
}

:global(.datepicker
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--end) {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  background-color: rgba(var(--clrPrimary));
}

:global(.datepicker.DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover) {
  background-color: rgba(var(--clrNtrlLight)) !important;
}

:global(.datepicker .DayPicker-Month) {
  margin: 0;
}

:global(.datepicker .DayPicker-NavButton) {
  top: 0;
  right: 0;
}
