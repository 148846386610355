.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.actionsWrapper {
  margin-top: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.actionsWrapper > * {
  margin: 0 12px;
}

.description {
  margin-top: 12px;
}
