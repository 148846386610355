.container {
  width: 60px;
  height: 100%;
  box-shadow: 2px 0 4px rgb(var(--clrBlack), var(--alpha));
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(var(--clrNtrlDark));
  z-index: 700;
}

.items {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 20px 0;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.item + .item {
  margin-top: 45px;
}
