.container {
  padding: 12px 0;
}

.container + .container {
  border-top: solid 1px rgba(var(--clrNtrlLight));
}

.label {
  margin-bottom: 6px;
}
