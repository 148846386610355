.button {
  box-shadow: 0 1px 5px rgba(var(--clrBlack), var(--alpha));
}

.button:hover {
  background-color: rgba(var(--clrNtrlDark));
  opacity: 1;
}

.button:hover > i {
  fill: rgba(var(--clrWhite));
}

.modal {
  max-width: 80%;
  height: 60%;
}
