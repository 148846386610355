.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.header {
  margin-bottom: 36px;
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.options {
  margin-right: 12px;
}

.searchWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex: 1;
}

.timeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 24px;
  width: 140px;
}

.timepickerField {
  max-width: 100%;
}

.actions {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.action + .action {
  margin-left: 12px;
}
