.container {
  width: 100%;
  margin-bottom: 18px;
}

.medium {
  max-width: 264px;
}

.small {
  max-width: 120px;
}

.xsmall {
  max-width: 102px;
}

.large {
  max-width: 546px;
}

.error {
  display: block;
  padding: 0;
  margin-top: 6px;
}

@media (max-width: 500px) {
  .medium {
    max-width: 100%;
    width: 100%;
  }

  .small {
    max-width: 120px;
    width: 100%;
  }

  .xsmall {
    max-width: 102px;
    width: 100%;
  }

  .large {
    max-width: 100%;
    width: 100%;
  }
}
