.header {
  border-bottom: solid 4px rgba(var(--clrNtrlLight));
  position: relative;
  padding-bottom: 6px;
  display: inline-flex;
}

.header:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100px;
  height: 4px;
  background-color: rgba(var(--clrNtrlMax));
}
