.content {
  position: absolute;
  left: 12px;
  bottom: 0;
  z-index: 1025;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 20%;
  padding: 0;
  max-height: 60%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
}

.content.collapsed {
  transform: translateY(calc(100% - 72px - 70px));
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 6px 12px 24px;
  background-color: rgba(var(--clrNtrlDark));
  height: 72px;
  box-sizing: border-box;
}

.collapseIcon {
  transition: transform 0.3s ease-in-out;
}

.content.collapsed .collapseIcon {
  transform: rotate(-180deg);
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  padding: 6px 12px;
  margin-bottom: 12px;
}

.tag {
  background-color: rgba(var(--clrSecondary));
  color: rgba(var(--clrWhite));
}

.infoContent {
  flex: 1;
  overflow: auto;
}

.row {
  margin: 0 12px;
  padding: 12px 0;
  display: flex;
  align-items: flex-start;
}

.row + .row {
  border-top: solid 1px rgba(var(--clrNtrlDark), 0.2);
}

.checkbox {
  margin-right: 6px;
  flex-shrink: 0;
}

.rowContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.rowHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.routeTags {
  display: flex;
  gap: 6px;
}

.groupDate {
  width: 100%;
  padding: 6px 12px;
  background-color: rgba(var(--clrNtrlLight));
  position: sticky;
  top: 0;
  z-index: 10;
}
