table {
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(var(--clrNtrlDark));
  border-radius: 3px;
}

.table.borderless {
  border-width: 0;
}

.tablehead {
  display: table-header-group;
}

.tablebody {
  display: table-row-group;
}

.tr {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0px;
  position: relative;
}

.table.hovered .tr .td {
  transition: background-color 0.3s ease;
}

.table.hovered .tr:hover td {
  background-color: rgba(var(--clrWhite), var(--alphaMin));
}

.tr.fill {
  background-color: rgba(var(--clrWhite), var(--alphaMin));
}

.tr.clickable {
  cursor: pointer;
}

.cell {
  display: table-cell;
  vertical-align: inherit;
  padding: 12px;
  border-style: solid;
}

.th {
  background-color: rgba(var(--clrNtrlDark));
  border-left-width: 0;
  border-top-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-color: rgba(var(--clrNtrlDark));
}

:is(th, td).sticky {
  top: 0;
  position: sticky;
  z-index: 2;
}

.table .th:last-of-type {
  border-right: 0;
}

.td {
  border-left-width: 0;
  border-top-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-color: rgba(var(--clrNtrlDark));
}

.table .td:last-of-type {
  border-right: 0;
}

.table .tablebody .tr:last-of-type .td {
  border-bottom-width: 0;
}

.table.borderless .td {
  border-right-width: 0;
}

.table.borderless .th {
  border-right-width: 0;
}

.tablePagination {
  width: 100%;
  padding: 12px;
  padding-right: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navigationWrapper {
  display: flex;
  align-items: center;
  margin-left: 12px;
  gap: 6px;
}

.resizer {
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  width: 5px;
  background: rgba(255, 255, 255, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: white;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
