.container {
  border: 0;
  outline: none;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 33px;
  transition: all 0.3s ease;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.container:hover {
  opacity: 0.6;
}

.container:active {
  transform: scale(0.9);
}

/* Variant */
.black {
  background-color: rgba(var(--clrBlack));
}

.white {
  background-color: rgba(var(--clrWhite));
}

.max {
  background-color: rgb(var(--clrNtrlMax));
}

.dark {
  background-color: rgb(var(--clrNtrlDark));
}

.light {
  background-color: rgb(var(--clrNtrlLight));
}

.min {
  background-color: rgb(var(--clrNtrlMin));
}

.primary {
  background-color: rgba(var(--clrPrimary));
}

.primaryLight {
  background-color: rgba(var(--clrPrimaryLt));
}

.secondary {
  background-color: rgba(var(--clrSecondary));
}

.secondaryLight {
  background-color: rgba(var(--clrSecondaryLt));
}

.info {
  background-color: rgba(var(--clrInfo));
}

.positive {
  background-color: rgba(var(--clrPositive));
}

.negative {
  background-color: rgba(var(--clrNegative));
}

.warning {
  background-color: rgba(var(--clrWarning));
}

.panda {
  background-color: rgba(var(--clrPanda));
}

.pandaDark {
  background-color: rgba(var(--clrPandaDark));
}

.flamingo {
  background-color: rgba(var(--clrFlamingo));
}

.cocodrile {
  background-color: rgba(var(--clrCocodrile));
}

.pig {
  background-color: rgba(var(--clrPig));
}

.koala {
  background-color: rgba(var(--clrKoala));
}

.parrot {
  background-color: rgba(var(--clrParrot));
}

.octupus {
  background-color: rgba(var(--clrOctopus));
}

.disabled {
  cursor: inherit;
  opacity: 0.6;
}
