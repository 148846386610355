:root {
  /* Corporative colors */
  --clrPrimary: 58, 206, 185;
  --clrPrimaryLt: 134, 190, 253;
  --clrSecondary: 248, 135, 41;
  --clrSecondaryLt: 195, 161, 240;

  /* Neutrals */
  --clrNtrlMax: 51, 51, 51;
  --clrNtrlDark: 4, 9, 65;
  --clrNtrlLight: 241, 243, 252;
  --clrNtrlLighter: 243, 243, 243;
  --clrNtrlMin: 235, 242, 247;

  /* auxiliary */
  --clrBlack: 0, 0, 0;
  --clrWhite: 255, 255, 255;

  /* Semantics */
  --clrNegative: 244, 87, 40;
  --clrNegativeLt: 255, 158, 163;
  --clrPositive: 119, 223, 88;
  --clrPositiveLt: 173, 242, 153;
  --clrWarning: 251, 150, 35;
  --clrWarningLt: 255, 204, 145;
  --clrInfo: 35, 136, 251;
  --clrInfoLt: 134, 190, 253;

  /* other colors */
  --clrVip: 212, 175, 55;

  --clrPanda: 27, 188, 155;
  --clrPandaDark: 22, 153, 127;
  --clrFlamingo: 41, 98, 255;
  --clrCocodrile: 255, 225, 26;
  --clrPig: 244, 67, 54;
  --clrKoala: 255, 102, 0;
  --clrParrot: 76, 18, 115;

  --clrOctopus: 223, 186, 255;

  /* Alpha */
  --alphaMin: 0.1;
  --alpha: 0.5;
  --alphaMax: 0.8;

  /* Font */
  --fontLetterSpacing: 2px;
}
