.font {
  padding: 0;
  margin: 0;
  font-weight: normal;
}

/* Weight */
.weight-400 {
  font-weight: 400;
}

.weight-500 {
  font-weight: 500;
}

.weight-600 {
  font-weight: 600;
}

.weight-700 {
  font-weight: 700;
}

/* Variant */
.black {
  color: rgba(var(--clrBlack));
}

.white {
  color: rgba(var(--clrWhite));
}

.max {
  color: rgb(var(--clrNtrlMax));
}

.dark {
  color: rgb(var(--clrNtrlDark));
}

.light {
  color: rgb(var(--clrNtrlLight));
}

.min {
  color: rgb(var(--clrNtrlMin));
}

.primary {
  color: rgba(var(--clrPrimary));
}

.secondary {
  color: rgba(var(--clrSecondary));
}

.info {
  color: rgba(var(--clrInfo));
}

.positive {
  color: rgba(var(--clrPositive));
}

.negative {
  color: rgba(var(--clrNegative));
}

.warning {
  color: rgba(var(--clrWarning));
}

.panda {
  color: rgba(var(--clrPanda));
}

.pandaDark {
  color: rgba(var(--clrPandaDark));
}

.flamingo {
  color: rgba(var(--clrFlamingo));
}

.cocodrile {
  color: rgba(var(--clrCocodrile));
}

.pig {
  color: rgba(var(--clrPig));
}

.koala {
  color: rgba(var(--clrKoala));
}

.parrot {
  color: rgba(var(--clrParrot));
}

.parrotLight {
  color: rgba(var(--clrParrotLight));
}

.octupus {
  color: rgba(var(--clrOctopus));
}

/* align */
.right {
  text-align: right;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

/* Size */
.size-xs {
  font-size: 10px;
}

.size-s {
  font-size: 12px;
}

.size-sm {
  font-size: 14px;
}

.size-m {
  font-size: 16px;
}

.size-ml {
  font-size: 18px;
}

.size-l {
  font-size: 22px;
}

.size-xl {
  font-size: 24px;
}

.size-xxl {
  font-size: 48px;
}

/* transform  */
.trans-capitalize {
  text-transform: capitalize;
}

.trans-uppercase {
  text-transform: uppercase;
}

.trans-lowercase {
  text-transform: lowercase;
}

.trans-inherit {
  text-transform: inherit;
}

/* Style */
.italic {
  font-style: italic;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disabled {
  opacity: var(--alpha);
}
