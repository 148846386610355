.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.header {
  margin-bottom: 36px;
}

.content {
  flex: 1;
  overflow: auto;
}

.actions {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.action + .action {
  margin-left: 12px;
}

.table tr {
  width: fit-content;
}
