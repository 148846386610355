.icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  vertical-align: middle;
  transition: all 0.3s ease;
}

.icon svg {
  display: block;
  width: 100%;
  height: 100%;
}

.tiny {
  width: 8px;
  height: 8px;
}

.small {
  width: 12px;
  height: 12px;
}

.medium {
  width: 16px;
  height: 16px;
}

.big {
  width: 24px;
  height: 24px;
}

.huge {
  width: 30px;
  height: 30px;
}

.extra-huge {
  width: 60px;
  height: 60px;
}

.icon {
  fill: rgba(var(--clrNtrlMax));
}

/* primary */
.primary {
  fill: rgba(var(--clrPrimary));
}

.primaryLight {
  fill: rgba(var(--clrPrimaryLt));
}

/* secondary */
.secondary {
  fill: rgba(var(--clrSecondary));
}

.secondaryLight {
  fill: rgba(var(--clrSecondaryLt));
}

/* info */
.info {
  fill: rgba(var(--clrInfo));
}

.infoLight {
  fill: rgba(var(--clrInfoLt));
}

/* positive */
.positive {
  fill: rgba(var(--clrPositive));
}

.positiveLight {
  fill: rgba(var(--clrPositiveLt));
}

/* warnign */
.warning {
  fill: rgba(var(--clrWarning));
}

.warningLight {
  fill: rgba(var(--clrWarningLt));
}

/* negative */
.negative {
  fill: rgba(var(--clrNegative));
}

.negativeLight {
  fill: rgba(var(--clrNegativeLt));
}

/* disabled */
.disabled {
  fill: rgba(var(--clrNtrlDark));
}

/* max */
.max {
  fill: rgba(var(--clrNtrlMax));
}

/* dark */
.dark {
  fill: rgba(var(--clrNtrlDark));
}

/* light */
.light {
  fill: rgba(var(--clrNtrlLight));
}

/* min */
.min {
  fill: rgba(var(--clrNtrlMin));
}

/* lighter */
.lighter {
  fill: rgba(var(--clrNtrlLighter));
}

/* white */
.white {
  fill: rgba(var(--clrWhite));
}

/* black */
.black {
  fill: rgba(var(--clrBlack));
}
