.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1024;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(var(--clrNtrlDark), var(--alphaMax));
  z-index: -1;
}

.modal {
  background-color: rgba(var(--clrWhite));
  width: 80%;
  max-width: 640px;
  box-sizing: border-box;
  min-height: 200px;
  max-height: 80%;
  position: relative;
  display: flex;
}

.content {
  width: 100%;
  overflow: visible;
  max-width: 1280px;
  padding: 24px;
}

:global(.modal-enter) .modal {
  opacity: 0;
  transform: scale(0.9);
}

:global(.modal-enter-active) .modal {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

:global(.modal-exit) .content {
  opacity: 1;
}

:global(.modal-exit-active) .modal {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

:global(.modal-enter) .overlay {
  opacity: 0;
}

:global(.modal-enter-active) .overlay {
  opacity: 1;
  transition: opacity 300ms;
}

:global(.modal-exit) .overlay {
  opacity: 1;
}

:global(.modal-exit-active) .overlay {
  opacity: 0;
  transition: opacity 300ms;
}
