div.contentWrapper {
  width: 75%;
  height: 100%;
  transition: width 0.3s ease;
  padding: 0;
  z-index: 640;
  display: flex;
  justify-content: center;
}

:global(.collapse-enter).contentWrapper {
  width: 75%;
}

:global(.collapse-enter-active).contentWrapper {
  width: 60px;
}

:global(.collapse-enter-done).contentWrapper {
  width: 60px;
}

:global(.collapse-exit).contentWrapper {
  width: 60px;
}

:global(.collapse-exit-active).contentWrapper {
  width: 75%;
}

:global(.collapse-exit-done).contentWrapper {
  width: 75%;
}

:global(.collapse-enter-active).contentWrapper {
  transition-delay: 0.3s;
}

:global(.collapse-exit-active).contentWrapper {
  transition: width 0.3s ease;
}

.content {
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
}

:global(.collapse-enter).contentWrapper .content {
  opacity: 1;
}

:global(.collapse-enter-active).contentWrapper .content {
  opacity: 0;
}

:global(.collapse-enter-done).contentWrapper .content {
  opacity: 0;
  display: none;
}

:global(.collapse-exit).contentWrapper .content {
  opacity: 0;
  display: block;
}

:global(.collapse-exit-active).contentWrapper .content {
  opacity: 1;
}

:global(.collapse-exit-done).contentWrapper .content {
  opacity: 1;
}

:global(.collapse-enter-active).contentWrapper .content {
  transition: opacity 0.3s ease;
}

:global(.collapse-exit-active).contentWrapper .content {
  transition-delay: 0.3s;
}

button.expandButton {
  display: none;
}

:global(.collapse-enter-done) button.expandButton {
  display: block;
}

.map {
  flex: 1;
}
