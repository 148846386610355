.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

.row > * {
  margin-right: 18px;
}

.row > *:last-child {
  margin-right: 0;
}

@media (max-width: 500px) {
  .row {
    flex-direction: column;
  }

  .row > * {
    margin-right: 0;
  }
}
