.content {
  position: absolute;
  left: 12px;
  bottom: 0;
  z-index: 1024;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 20%;
  height: 60%;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}

.content.collapsed {
  transform: translateY(calc(100% - 72px));
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 6px 12px 24px;
  background-color: rgba(var(--clrNtrlDark));
  height: 72px;
  box-sizing: border-box;
}

.collapseIcon {
  transition: transform 0.3s ease-in-out;
}

.content.collapsed .collapseIcon {
  transform: rotate(-180deg);
}

.infoContent {
  flex: 1;
  overflow: auto;
  padding: 24px;
}

.infoHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.editButton {
  flex: 0;
}

.gasWrapper {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.fuelPercent {
  margin-left: 24px;
}
