.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: rgba(var(--clrNtrlLight));
}

.content {
  display: flex;
  align-items: center;
}

.content .welcomeCard {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 24px;
  width: 240px;
  height: 340px;
  box-shadow: 0 2px 12px rgba(var(--clrNtrlMax), var(--alpha));
  background: rgba(var(--clrNtrlDark));
}

.content .formCard {
  width: 280px;
  height: 380px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(var(--clrWhite));
  box-shadow: 0 2px 12px rgba(var(--clrNtrlMax), var(--alpha));
}

.logo {
  align-self: center;
}

.button {
  margin-top: 24px;
  width: 100%;
}

@media (max-width: 500px) {
  .content .welcomeCard {
    display: none;
  }
}
