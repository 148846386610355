.status {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  border: solid 1px #fff;
  box-sizing: border-box;
}

.status.off {
  background-color: #546e7a;
  border-color: #546e7a;
}

.status.on {
  background-color: #8bc34a;
  border-color: #8bc34a;
}

.status.idle {
  background-color: #f48020;
  border-color: #f48020;
}
